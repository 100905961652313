<template>

  <div>
    <!-- Event card -->
    <div class="eventCard">
      <div class="eventCard--title">
        <span class="eventCard--title__text">{{ `${$t('backend_trans_keys.' + event.transmitter.typeTransKey)} [${event.transmitter.id}]` }}</span>
        <span class="eventCard--title__subText">{{ displayDate(event.createdAt) }}</span>
      </div>

      <div class="eventCard--content text-center">
        <p class="italic">{{ $t('pages.pendingEvent_index.new_transmitter-card.description') }}</p>

        <p class="mt-4">{{ $t('pages.culture_events.new_transmitter-card.modal-nbSensors') }} : <b>{{ event.transmitter.nbSensors }}</b></p>

        <!-- Cultures available to link it -->
        <div v-if="cultures.length > 0">
          <div class="mt-2">

            <select v-model.number="userChoice.cultureId" class="ml-2 select-basic" style="max-width: -webkit-fill-available">
              <option value="-1" selected>{{ $t('pages.culture_events.new_transmitter-card.modal-none') }}</option>
              <option v-for="culture in cultures" :key="culture.id" :value="culture.id">{{ `${culture.name} (${$t('backend_trans_keys.'+culture.typeTransKey)} ${$t('backend_trans_keys.'+culture.varietyTransKey)})` }}</option>
            </select>
          </div>

          <button @click="acquitEvent" class="btn btn--primary mt-4">{{ $t('words.acquit') }} <i class="ml-2 fas fa-check"></i></button>
        </div>
        <!-- No cultures available -->
        <div v-else class="mt-2 italic">
          {{ $t('pages.pendingEvent_index.new_transmitter-card.noCultureAvailable') }}
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {apiPostRequest, displayDateByLocale} from "../../utils";
import {
  API_EVENTS_ACK_NEW_TRANSMITTER_SUFFIX,
  API_EVENTS_SHOW,
} from "../../utils/constants";
export default {
  name: "NewTransmitterEvent",
  components: {},
  props: {
    event: {
      type: Object,
      required: true
    },
    cultures: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      userChoice: {
        cultureId: -1,
      }
    }
  },
  mounted() {
  },
  methods: {
    displayDate: function(d) {
      d = d.slice(0,-1) // Remove last millisecond entry in array to prevent moment.js date error
      return displayDateByLocale(d)
    },
    acquitEvent: function(){
      apiPostRequest(API_EVENTS_SHOW + this.event.id + API_EVENTS_ACK_NEW_TRANSMITTER_SUFFIX, {
        "cultureId": this.userChoice.cultureId,
        "isReplacement": false,
        "continueMeasures": false
      })
          .then(() => {
            // Emit to the parent that the event has been acquitted
            this.$emit("acquitted")
          })
    }
  }
}
</script>

<style scoped>

</style>
