<template>
  <div v-if="isLoaded">

    <h1 class="mb-4 text-lg font-semibold">{{ $t('pages.culture_events.second-title', {name: this.cultureName}) }}</h1>

    <!-- inactive_transmitter events-->
    <div class="card">
      <h2 class="card__title">{{ $t('pages.culture_events.inactive_transmitter-title', {count: this.events.inactiveEvents.length}) }}</h2>
      <p class="mb-4">{{ $t('pages.culture_events.inactive_transmitter-description') }}</p>

      <button v-if="events.inactiveEvents.length > 1" @click="acquitAllInactiveEvent" class="mb-4 btn-outlined btn-outlined--primary">{{ $t('words.acquit-all') }} <i class="ml-2 fas fa-check"></i></button>

      <!-- No events-->
      <div v-if="this.events.inactiveEvents.length === 0" class="italic">
        {{ $t('pages.culture_events.no-event-now') }}
      </div>
      <!-- Display events-->
      <div v-else class="flex flex-wrap">
        <div v-for="(inactiveEvent, i) in events.inactiveEvents" :key="inactiveEvent.id">
          <inactive-transmitter-event @acquitted="oneEventAcquitted(inactiveEvent, i)" :event="inactiveEvent" />
        </div>
      </div>

    </div>


    <!-- New transmitter events-->
    <div class="card mt-4">
      <h2 class="card__title">{{ $t('pages.culture_events.new_transmitter-title', {count: this.events.newTransmitterEvents.length}) }}</h2>
      <p class="mb-4">{{ $t('pages.culture_events.new_transmitter-description') }}</p>

      <!-- No events-->
      <div v-if="this.events.newTransmitterEvents.length === 0" class="italic">
        {{ $t('pages.culture_events.no-event-now') }}
      </div>
      <!-- Display events-->
      <div v-else class="flex flex-wrap">
        <div v-for="(event, i) in events.newTransmitterEvents" :key="event.id">
          <new-transmitter-replacement-event @acquitted="oneEventAcquitted(event, i)" :event="event" :user-id="userId" :disabled="i > 0"/>
        </div>
      </div>
    </div>


    <!-- Sensor management events-->
    <div class="card mt-4">
      <h2 class="card__title">{{ $t('pages.culture_events.sensor_management-title', {count: this.events.sensorManagementEvents.length}) }}</h2>
      <p class="mb-4">{{ $t('pages.culture_events.sensor_management-description') }}</p>

      <!-- No events-->
      <div v-if="this.events.sensorManagementEvents.length === 0" class="italic">
        {{ $t('pages.culture_events.no-event-now') }}
      </div>
      <!-- Display events-->
      <div v-else class="flex flex-wrap flex-col">
        <div >
          <button v-if="events.sensorManagementEvents.length > 1" class="mb-4 btn-outlined btn-outlined--primary" @click="acquitAllManagementEvents">
            {{ $t('words.acquit-all') }}
            <i class="ml-2 fas fa-check"/>
          </button>
        </div>
        <div class="flex flex-wrap">
          <div v-for="(event, i) in events.sensorManagementEvents" :key="event.id">
            <sensor-management-event @acquitted="oneEventAcquitted(event, i)" :event="event" :disabled="i > 0"/>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../utils";
import {
  API_CULTURES_SHOW,
  API_CULTURES_PENDING_EVENTS_PREFIX,
  API_EVENTS_SIMPLE_ACK_SUFFIX,
  API_EVENTS_INDEX,
  EVENT_TYPE_INACTIVE_TRANSMITTER,
  EVENT_TYPE_NEW_TRANSMITTER,
  EVENT_TYPE_SENSOR_MANAGEMENT
} from "../../../utils/constants";
import NewTransmitterReplacementEvent from "../../../components/events/NewTransmitterReplacementEvent";
import SensorManagementEvent from "../../../components/events/SensorManagementEvent";
import InactiveTransmitterEvent from "../../../components/events/InactiveTransmitterEvent";

export default {
  name: "CultureEvents",
  components: {InactiveTransmitterEvent, SensorManagementEvent, NewTransmitterReplacementEvent},
  data: function() {
    return {
      id: this.$route.params.id,
      isLoaded: false,
      cultureName: "",
      userId: null,
      events: {
        inactiveEvents: [],
        newTransmitterEvents: [],
        sensorManagementEvents: []
      }
    }
  },
  mounted() {
    this.isLoaded = false
    // Fetch all events
    apiGetRequest(API_CULTURES_SHOW + this.id + API_CULTURES_PENDING_EVENTS_PREFIX)
        .then((res) => {
          this.cultureName = res.data.culture
          this.userId = res.data.userId
          this.events.inactiveEvents = res.data.events.inactiveEvents
          this.events.newTransmitterEvents = res.data.events.newTransmitterEvents
          this.events.sensorManagementEvents = res.data.events.sensorManagementEvents
          this.isLoaded = true
        })
  },
  methods: {
    /**
     * One event has been acquitted
     * @param event The acquitted event
     * @param arrayIndex The position in its respective array
     */
    oneEventAcquitted: function(event, arrayIndex) {
      if(event.type === EVENT_TYPE_INACTIVE_TRANSMITTER){
        this.events.inactiveEvents.splice(arrayIndex, 1)
      }else if(event.type === EVENT_TYPE_NEW_TRANSMITTER){
        this.events.newTransmitterEvents.splice(arrayIndex, 1)
      }else if(event.type === EVENT_TYPE_SENSOR_MANAGEMENT){
        this.events.sensorManagementEvents.splice(arrayIndex, 1)
      }

      // Don't forget to update the global stored pendingEventsCount and display success alert
      this.$store.commit('setPendingEventsCount', this.$store.state.pendingEventsCount - 1)
      this.displaySuccessAlert(1)
    },
    /**
     * Acquit all inactive events
     */
    acquitAllInactiveEvent: function() {
      let ackArray = []
      for (const event of this.events.inactiveEvents) {
        ackArray.push({
          id: event.id,
          comment: event.acquittedComment
        })
      }

      // Send the request with all events in json data
      apiPostRequest(API_EVENTS_INDEX + API_EVENTS_SIMPLE_ACK_SUFFIX, {
        "events" : ackArray
      })
        .then(() => {
          // Don't forget to update the global stored pendingEventsCount and display success alert
          this.$store.commit('setPendingEventsCount', this.$store.state.pendingEventsCount - this.events.inactiveEvents.length)
          this.displaySuccessAlert(this.events.inactiveEvents.length)
          this.events.inactiveEvents = []
        })
    },
    acquitAllManagementEvents : function () {
      // Send the request with all events in json data
      apiPostRequest(API_EVENTS_INDEX + API_EVENTS_SIMPLE_ACK_SUFFIX, {
        "events": this.events.sensorManagementEvents.map(event => ({id:event.id, comment: ""}))
      })
          .then(() => {
            // Don't forget to update the global stored pendingEventsCount and display success alert
            this.$store.commit('setPendingEventsCount', this.$store.state.pendingEventsCount - this.events.sensorManagementEvents.length)
            this.displaySuccessAlert(this.events.sensorManagementEvents.length)
            this.events.sensorManagementEvents = []
          })
    },

    /**
     * Display the success alert
     * @param count The number of events that has been acquitted
     */
    displaySuccessAlert: function (count) {
      const successMsg = this.$tc('pages.culture_events.success-message', count)
      displayAlertSuccess(successMsg)
    }
  }
}
</script>

<style scoped>

</style>
