<template>
  <ValidationObserver ref="userForm" v-slot="{ handleSubmit, /*invalid*/}">
    <form @submit.prevent="handleSubmit(onSubmitWithData)">

      <div class="mt-3 mb-3">
        <ValidationProvider :name="forms.email.label" rules="required|max:255|email" v-slot="{errors}">
          <label for="email" class="form__label">{{forms.email.label}}<Required/></label>
          <input id="email" type="text" class="form__input w-full" v-model="forms.email.value"/>
          <p class="form__error">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>
      <div class="grid grid-cols-2 gap-3 mt-3 mb-3">
        <div>
          <ValidationProvider :name="forms.firstname.label" rules="required|max:255" v-slot="{errors}">
            <label for="firstname" class="form__label">{{forms.firstname.label}}<Required/></label>
            <input id="firstname" type="text" class="form__input w-full" v-model="forms.firstname.value"/>
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div>
          <ValidationProvider :name="forms.lastname.label" rules="required|max:255" v-slot="{errors}">
            <label for="lastname" class="form__label">{{forms.lastname.label}}<Required/></label>
            <input id="lastname" type="text" class="form__input w-full" v-model="forms.lastname.value"/>
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>
      <div class="grid grid-cols-6 gap-3 mt-3 mb-3">
        <div>
          <ValidationProvider :name="forms.npa.label" rules="required|max:255|numeric|min_value:1" v-slot="{errors}">
            <label for="npa" class="form__label">{{forms.npa.label}}<Required/></label>
            <input id="npa" type="number" class="form__input w-full" v-model="forms.npa.value"/>
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="col-span-2">
          <ValidationProvider :name="forms.city.label" rules="required|max:255" v-slot="{errors}">
            <label for="city" class="form__label">{{forms.city.label}}<Required/></label>
            <input id="city" type="text" class="form__input w-full" v-model="forms.city.value"/>
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="col-span-3">
          <ValidationProvider :name="forms.street.label" rules="required|max:255" v-slot="{errors}">
            <label for="street" class="form__label">{{forms.street.label}}<Required/></label>
            <input id="street" type="text" class="form__input w-full" v-model="forms.street.value"/>
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
      </div>
      <div v-if="!profile" class="mt-3 mb-3">
        <label for="role" class="form__label">{{forms.role.label}}<Required/></label>
        <select v-model="forms.role.value" class="form__input w-full">
          <option v-for="role in allRoles" :key="role.value" :value="role.value.toUpperCase()">{{role.label}}</option>
        </select>
      </div>

      <button class="mt-6 btn btn--success"><i class="fas fa-check mr-2"></i>{{!isEdit ? $t('pages.user_create.validate') : $t('pages.user_update.validate')}}</button>

    </form>
  </ValidationObserver>
</template>

<script>
import {ADMIN_ROLE, FARMER_ROLE} from "@/utils/constants";
import Required from "@/components/forms/Required.vue";

export default {
  components: {Required},
  props:{
    onSubmit:{
      type:Function,
      required:true,
    },
    errorsForm:{
      type:Object,
      required:false,
    },
    userInfo:{
      type:Object,
      required:false
    },
    isEdit:{
      type:Boolean,
      require:false,
      defaults:false,
    },
    profile:{
      type:Boolean,
      require:false,
      defaults:false
    }
  },
  name: "UserForm",
  watch:{
    'errorsForm':{
      handler(errorsForm){
        this.$refs.userForm.setErrors(errorsForm)
      }
    },
    'userInfo':{
      handler(userInfo){
        for(const field in userInfo){
          this.forms[field].value = userInfo[field]
        }
      }
    }
  },
  data:function(){
    return {
      forms:{
        email:{
          label:this.$t('words.email'),
          value: null
        },
        firstname:{
          label:this.$t('words.firstname'),
          value:null
        },
        lastname:{
          label:this.$t('words.lastname'),
          value:null
        },
        npa:{
          label:this.$t('words.npa'),
          value:null
        },
        city:{
          label:this.$t('words.city'),
          value:null
        },
        street:{
          label:this.$t('words.street'),
          value:null
        },
        role:{
          label:this.$t('words.role'),
          value:FARMER_ROLE.toUpperCase()
        }
      },
      allRoles:[
        {label:this.$t('words.farmer'),value:FARMER_ROLE},
        {label:this.$t('words.admin'),value:ADMIN_ROLE},
      ]
    }
  },
  methods:{
    onSubmitWithData:function(){
      let data = {
        email: this.forms.email.value,
        firstname: this.forms.firstname.value,
        lastname: this.forms.lastname.value,
        npa: this.forms.npa.value,
        city: this.forms.city.value,
        street: this.forms.street.value,
        role: this.forms.role.value
      }
      this.onSubmit(data)
    }
  }
}
</script>

<style scoped>

</style>