<template>
  <l-map ref="map" @ready="mapFitMarkers" :crs="layer.crs" :zoom="defaultZoom" :center="center" :options="{zoomControl: true}" style="width: 100%; height: 100%">
    <!-- Custom top left control-->
    <l-control>
      <slot name="customControl"></slot>
    </l-control>
    <!-- Real map-->
    <l-tile-layer v-if="chosenLayer === 0" :url="layer.url"></l-tile-layer>
    <!-- Custom plan -->
    <l-image-overlay v-if="chosenLayer === 1" :url="layer.url" :bounds="bounds" ></l-image-overlay>

    <marker-transmitter v-for="transmitter in displayableTransmitters" :key="transmitter.id" :transmitter="transmitter" :draggable="editionMode"/>

  </l-map>
</template>

<script>
import {LMap, LImageOverlay, LTileLayer, LControl} from 'vue2-leaflet';
import {CRS} from 'leaflet'
import MarkerTransmitter from "./marker-transmitter";
import {API_UPLOAD_MAPS_ROUTE} from "../../utils/constants";

const GEOGRAPHICAL_MAP = 0
const CUSTOM_MAP = 1

export default {
  name: "InteractiveMap",
  components: {
    MarkerTransmitter,
    LMap,
    LImageOverlay,
    LTileLayer,
    LControl
  },
  props: {
    // True if we ask for a geographical map
    // False if we ask to use a custom user image map
    isGeoMap: {
      type: Boolean,
      required: true
    },
    transmitters: {
      type: Array,
      required: true
    },
    mapImageName: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      map: null,
      defaultZoom: 0,
      // 0 => real map
      // 1 => custom plan
      chosenLayer: this.isGeoMap ? GEOGRAPHICAL_MAP : CUSTOM_MAP,
      layers: [
        {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          crs: CRS.EPSG3857
        },
        {
          url: API_UPLOAD_MAPS_ROUTE+'/'+this.mapImageName,
          crs: CRS.Simple
        }
      ],
      bounds: [[0, 0], [750, 1000]], // Ratio of 4:3 (uploaded plan must have this ratio to be perfectly displayed)
    }
  },
  methods: {
    /**
     * Only used if the chosen layer is geographical map
     * Called when the map is ready to fit the map so that all markers are visible and set automatically the
     * zoom to be the better.
     *
     * For custom map, it will
     */
    mapFitMarkers: function (){
      if(this.chosenLayer === GEOGRAPHICAL_MAP){
        this.$refs.map.mapObject.fitBounds(this.displayableTransmitters.map(t => [t.latitude, t.longitude]))
      }
    }
  },
  computed: {
    layer: function() {
      return this.layers[this.chosenLayer]
    },
    /**
     * Custom plan has center [bounds / 2]
     * Geographical map will have default [0,0]. But if their already is some markers, the mapFitMarkers() method
     * will be called and center the map automatically to show all markers.
     * @returns {number[]}
     */
    center: function() {
      switch (this.chosenLayer) {
        case CUSTOM_MAP:
          return [this.bounds[1][0] / 2, this.bounds[1][1] / 2]
        case GEOGRAPHICAL_MAP:
            return [0,0]
      }
      return this.chosenLayer === CUSTOM_MAP ? [this.bounds[1][0] / 2, this.bounds[1][1] / 2] : [0,0]
    },
    /**
     * All transmitters of type "Simple Transmitter" that have a latitude / longitude value
     * @returns {*[]}
     */
    displayableSimpleTransmitters: function() {
      return this.transmitters.filter(t => !t.weatherStation && t.latitude !== null && t.longitude !== null)
    },
    /**
     * All transmitters of type "Weather Station" that have a latitude / longitude value
     * @returns {*[]}
     */
    displayableWeatherStations: function() {
      return this.transmitters.filter(t => t.weatherStation && t.latitude !== null && t.longitude !== null)
    },
    /**
     * All transmitters of any type that have a latitude / longitude value
     * @returns {*[]}
     */
    displayableTransmitters: function(){
      return this.displayableSimpleTransmitters.concat(this.displayableWeatherStations)
    },
  }
}
</script>

<style scoped>
</style>
