<template>
  <!-- Container (match full width / height of the parent) -->
  <div class="w-full h-full text-center border border-gray-500 rounded-md relative">
    <!-- Colored bar -->
    <div class="bg-red-500 rounded-md h-full" :class="level >= 60 ? 'bg-green-500' : level >= 20 ? 'bg-yellow-500' : 'bg-red-500'" :style="{ width: level === -1 ? 0 : level + '%'}"></div>
    <!-- Small text that can be displayed if we want-->
    <div v-if="displayPercent" class="font-semibold absolute -top-0.5 left-2 w-auto m-auto" style="font-size: 11px">
      <span v-if="level === -1">{{ $t('words.unknown') }}</span>
      <span v-else>{{ level }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "battery-indicator",
  props: {
    level: {
      type: Number,
      required: true
    },
    displayPercent: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
