<template>
  <div>
    <!-- New culture btn -->
    <router-link :to="{name: 'culture_create', params: { farmerId: this.$store.state.user.id }}">
      <button class="btn btn--success"><i class="fas fa-plus mr-2"></i>{{ $t('pages.culture_index.new-culture') }}</button>
    </router-link>

    <!-- All cultures -->
    <div v-if="isLoaded" class="mt-4">
      <div class="card">
        <h2 class="card__title">{{ $t('pages.culture_index.cards.cultures.title', {'count':culturesTable.data.length}) }}</h2>
        <data-table v-if="culturesTable.data" :columns="culturesTable.columns" :data="culturesTable.data" />
      </div>
    </div>
  </div>

</template>

<script>

import {apiGetRequest, displayDateByLocale} from "../../../utils";
import {API_CULTURES_INDEX} from "../../../utils/constants";
import DataTable from "../../../components/general/datatable/data-table";

export default {
  name: "CultureIndex",
  components: {DataTable},
  props: {},
  data: function() {
    return {
      isLoaded: false,
      culturesTable : {
        columns: {
          name: this.$t('words.name'),
          cultivatedVariety: this.$t('pages.culture_index.cards.cultures.columns.cultivatedVariety'),
          cultureType: this.$t('pages.culture_index.cards.cultures.columns.cultureType'),
          nouaisonDate: this.$t('pages.culture_index.cards.cultures.columns.nouaisonDate'),
          nbTransmitters: this.$t('words.nbTransmitters'),
          actions: this.$t('words.actions'),
        },
        data: null
      }
    }
  },
  mounted() {
    this.loadCultures()
  },
  methods: {
    /**
     * Load all cultures info
     */
    loadCultures: function() {
      this.isLoaded = false
      apiGetRequest(API_CULTURES_INDEX)
          .then((res) => {
            this.culturesTable.data = res.data.map((c) => {
              c.cultivatedVariety = `${this.$t('backend_trans_keys.'+c.typeTransKey)} (${this.$t('backend_trans_keys.'+c.varietyTransKey)})`
              c.cultureType = c.outside ? this.$t('words.outside') : this.$t('words.inside')
              c.nouaisonDate = c.nouaisonDate === null ? '-' : this.displayDate(c.nouaisonDate)
              c.actions = {
                see: {
                  to: {name: 'culture_show', params:{id: c.id}},
                  text: this.$t('words.see')
                },
                edit: {
                  to: {name: 'culture_edit', params:{id: c.id}},
                  text: this.$t('words.edit')
                }
              }
              return c
            })

            this.isLoaded = true
          })
    },
    /**
     * Display date by moment.js locale
     * @param date The date to display
     * @returns {string} A strig representation of the date, well formatted by the current locale
     */
    displayDate: function(date) {
      return displayDateByLocale(date, true)
    }
  }
}
</script>

<style scoped>

</style>
