import moment from "moment";
import {i18n} from "../locales";

/**
 * Construct a well formatted "from" query param for the request to the backend, according the selected time period selected.
 * The "?from=" query is the timestamp from which we want to fetch the measures
 * @param selectedTimePeriod The selected time period
 * @returns {string}
 */
function getFromQueryParam(selectedTimePeriod){
  // Get the amount and unit according the current selected timePeriod and generate the fromQuery GET param
  const amountUnit = getMomentAmountUnitFromSelectedTimePeriod(selectedTimePeriod)
  let fromQueryParam = ""
  if(amountUnit != null){
    // Ask the API to fetch all measures >= now() - X days /!\ use UTC time
    const fromTimestampFilter = moment.utc().subtract(amountUnit.amount, amountUnit.unit).unix()
    fromQueryParam = "?from=" + fromTimestampFilter
  }

  return fromQueryParam
}

/**
 * Get query params string for "from" and "to" date when asking a custom period.
 * Remove the time part of the date.
 * @param rangeDates Object with 2 attributes start and end, both dates
 * @returns {string}
 */
function getFromAndToQueryParam(rangeDates){
  // Add time to force UTC.
  const startDate = moment(`${rangeDates.start} 00:00:00+00:00`).utc()
  const endDate = moment(`${rangeDates.end} 23:59:59+00:00`).utc()
  return `?from=${startDate.unix()}&to=${endDate.unix()}`
}

/**
 * Return a HighChart options object that contains all stuff to display a LineCharts (multiple lines), including time series
 * according the MeasureType object in parameter.
 * @param measureType A measure type object that correspond to the Object return by the backend server when asking to fetch all measures
 * @param errorExportCallback A callback that will be called if an error occurred when we have an error while we want to export the chart
 * @param forceMarker Force the marker to be displayed nevermind the type of screen (size etc..) you are. It can be usefull for edit measures purpose.
 * To show exactly what we select. Default undefined : marker are displayed while it's not too dense
 * @returns A HighChart options object
 */
function getLineChartOptionsByMeasureType(measureType, errorExportCallback, forceMarker = undefined){
  return {
    plotOptions: {
      series: {
        turboThreshold: 0 // To display all values without limit of numbers
      }
    },
    chart: {
      type: 'line'
    },
    title: {
      text: i18n.t('backend_trans_keys.'+measureType.typeTransKey)
    },
    yAxis: {
      title: {
        text: i18n.t('backend_trans_keys.'+measureType.typeTransKey) + ` [${measureType.symbol}]`
      }
    },
    xAxis: {
      type: 'datetime',
      // https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat
      dateTimeLabelFormats: {
        millisecond:  '%d.%m.%Y<br/>%H:%M:%S',
        second:       '%d.%m.%Y<br/>%H:%M:%S',
        minute:       '%d.%m.%Y<br/>%H:%M:%S',
        hour:         '%d.%m.%Y<br/>%H:%M:%S',
        day:          '%d.%m.%Y',
        week:         '%d.%m.%Y',
        month:        '%m.%Y  ',
        year:         '%Y',
      }
    },
    series: measureType.sensors.map(s => {
      return {
        name: i18n.t('backend_trans_keys.'+s.typeTransKey) + " " + s.id,
        showInLegend: true,
        color:s.color || undefined,
        dashStyle:s.dashStyle||undefined,
        lineWidth:s.lineWidth||2,
        marker:{enabled:s.marker!==undefined ? s.marker.enabled : undefined},
        data: s.values.map((valueDTO) => {
          return {
            x: valueDTO.timestamp,
            y: valueDTO.value,
            docId: valueDTO.docId,
            marker: {
              enabled: forceMarker
            }
          }
        })
      }
    }),
    exporting: {
      fallbackToExportServer: false,
      error: () => errorExportCallback()
    },
    tooltip: {
      xDateFormat: '%d.%m.%Y %H:%M:%S',
      valueSuffix: `${measureType.symbol}`
    },
    credits: {
      enabled: false
    }
  }
}

/**
 * Return an "amount - unit" object according the selected time period in parameter.
 * amount and unit respect the "moment.js" subtract method that can be used to subtract an amount of certain unit
 * from a date (https://momentjscom.readthedocs.io/en/latest/moment/03-manipulating/02-subtract/)
 * @param selectedTimePeriod integer value that represent the selected time period [1-4]
 * @returns {null|{amount: number, unit: string}} null if we ask for all data, an object containing asked amount and unit otherwise
 *
 */
function getMomentAmountUnitFromSelectedTimePeriod(selectedTimePeriod) {
  // Default values
  let allData = false
  let amount = 7
  let unit = 'days'

  switch (selectedTimePeriod) {
    // Default (7 days)
    case 1:
      break;
    // 2 days
    case 2:
      amount = 2
      break;
    // one day
    case 3:
      amount = 1
      break;
    // All data
    case 4:
      allData = true
      break;
    // All other values => default 7 days
    default:
      break;
  }

  if(allData){
    return null
  }else{
    return {
      amount: amount,
      unit: unit
    }
  }
}

function huntCurve(xx, a, b, c){
  return xx.map(x=>a/(1+1.46*Math.E**(-c*x)))
}

function formatLocalDate(d){
  return d instanceof Array ? `${String(d[2]).padStart(2, '0')}.${String(d[1]).padStart(2, '0')}.${d[0]}T${String(d[3]).padStart(2, '0')}:${String(d[4]).padStart(2, '0')}:${String(d[5]).padStart(2, '0')}` : d
}

function formatModel(model){
  return {value:model.id, name:formatNameModel(model)}
}
function formatNameModel(model){
  return `${model.name}(${formatLocalDate(model.createdAt)})`
}

export {getFromQueryParam, getFromAndToQueryParam,getLineChartOptionsByMeasureType, huntCurve, formatLocalDate,formatModel}
