import VueRouter from "vue-router";
import {alreadyAuth, checkAccessMiddleware, disabledRoutes, hasRole, setHeadTitle, setLayout} from "./middlewares";
import publicRoutes from "./public_routes";
import appRoutes from "./app_routes";
import Vue from "vue";
import Error4XX from "../views/app/Error4XX";
import {ADMIN_ROLE, FARMER_ROLE} from "../utils/constants";

Vue.use(VueRouter)

// Concat all routes (careful to the order)
// And add at the end the error 4XX for all other not implemented routes
const routes = publicRoutes.concat(appRoutes).concat([
  {
    name: 'error_4xx',
    path: '/:pathMatch(.*)*',
    component: Error4XX,
    props: true,
    meta: {
      auth: true,
      roles: [ADMIN_ROLE, FARMER_ROLE]
    }
  },
])

const router = new VueRouter({
  mode: 'history',
  routes: routes
})


// Navigation guards use as middleware to filter before each navigation from a route to another
router.beforeEach(checkAccessMiddleware)
router.beforeEach(alreadyAuth)
router.beforeEach(disabledRoutes)
router.beforeEach(hasRole)
router.beforeEach(setLayout)
router.beforeEach(setHeadTitle)

export default router
