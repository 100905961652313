import {i18n} from "../locales";
import axios from "axios";
import store from "../store"
import router from "../router";
import {AlertTypes, API_REFRESH_TOKEN} from "./constants";
import moment from "moment";

const serverErrorAlertObj = {
  type: AlertTypes.DANGER,
  msg: i18n.t('validations.server_error')
}


/**
 * Generate the head title according the pageName
 * @param pageName The page name that will be use to fetch the right head title translation
 * @returns {string} The default head title if the translation has not been found, a full head title according the page
 * head-title translation otherwise
 */
function generatePageHeadTitle(pageName) {
  let headTitle = 'FGAS'
  const tKey = 'pages.'+pageName+'.head-title';

  if(i18n.te(tKey)){
    headTitle = headTitle + ' | ' + i18n.t(tKey)
  }

  return headTitle
}

/**
 * Utility function to display date time (UTC) in a moment.js format according the locale chosen
 * @param d String representation (ISO 8601)
 * @returns {string} The date time formatted
 */
function displayDateTimeByLocaleFromISOString(d){
  return moment.utc(d).format('L LT')
}

/**
 * Utility function to display date (UTC) in a moment.js format according the locale chosen
 * @param d ARRAY that is the date [yyyy, m, d] or [yyyy, m, d, h, m, s]
 * @param noTime True if we only want date, otherwise we have date time
 * @returns {string} The date formatted
 */
function displayDateByLocale(d, noTime = false){
  let newDateArray = [...d];
  newDateArray[1]-- // /!\ Careful that in JS month are indexed from [0-11] (in opposition to MySQL that they are from [1-12] /!\
  // Specific for case we want to display the time
  if(!noTime){
    // DateTime format can be :
    // 1. 2022-04-20 10:40:00.000000
    // 2. 2022-04-20 10:40:23.145724
    // The dateTime array has length of 7. In the first case, due to 00.000000, the array will just have 5 length
    // To be consistent, always generate a 5 length array => Moment.JS don't accept an array of more than 5 length.
    if(newDateArray.length > 5){
      newDateArray.splice(5, 2)
    }
  }
  return noTime ? moment.utc(newDateArray).format('L') : moment.utc(newDateArray).format('L LT')
}

/**
 * Utility function to display date (UTC) from timestamp to date in a moment.js format according the locale chosen
 * @param d The date timestamp
 * @returns {string} The date formatted
 */
function displayDateTimestampByLocale(d){
  return moment.unix(d).utc().format('L LT')
}

/**
 * From a specific timestamp, return a string representation of the elapsed time (xx minutes ago, ...)
 * We use moment.js and the current locale() load in moment.js for the language (according the chosen locale in the app)
 * @param timestamp Timestamp value
 * @returns {string} A string representation of the elapsed time (generate by moment js)
 */
function displayElapsedTimeToTimestamp(timestamp){
  let str = moment.unix(timestamp).utc().startOf('minute').fromNow()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Logout the current authenticate user and redirect to the login page
 * @param expired True if the cause is a session expiration, false otherwise.
 * this param is pass as props to the login route that will be able to display the right alert message
 */
function logout(expired= false) {
  store.commit("logout")
  router.push({name: 'login', params: { sessionExpired: expired }}).then()
}

/**
 * Sensor may have a letter like dendrometer (A, B, C, D) that depends on the 2 first digit of the id
 * @param sensorId The sensor id
 * @returns {string} The letter A, B, C or D ("-" if not a right id or not the right sensor type)
 */
function getLetterForSensor(sensorId) {
  switch (String(sensorId).slice(0,2)) {
    case '12':
      return 'A'
    case '16':
      return 'B'
    case '20':
      return 'C'
    case '24':
      return 'D'
    default :
      return '-'
  }
}

/**
 * Send a GET request to the API
 * The access token will be sent as a bearer token. In case of expiration, we'll automatically send a
 * refresh token request to generate new pairs of access / refresh token according the current refresh token sent.
 *
 * In case of refresh token expiration, redirect to login page due to session expiration (inactivity)
 * In case of other error (500 server, ...), display a global alert error that is in the "AppLayout" layout
 *
 * @param url the url
 * @returns {Promise<unknown>}
 */
function apiGetRequest(url){
  return new Promise(function(resolve, reject){
    axios.get(url, {
      // Add the current stored access token to access the API resource
      headers: {
        "Authorization": "Bearer " + store.state.accessToken
      }
    })
      // All works
      .then((res) => {
        resolve(res)
      })
      // Error
      .catch((e) => {
        // Access token expired
        if(e.response.headers['x-expired-token'] !== undefined){
          refreshTokens()
            // Successfully refreshed
            .then(() => {
              // Re-run the initial request
              apiGetRequest(url)
                .then((res) => resolve(res))
                .catch(() => store.commit("addAlertNotification", serverErrorAlertObj))
            })
            // Error when refreshing the token
            .catch((e) => {
              // Server ask us to retry the refresh request because a previous request has changed the accepted refresh token (few seconds ago) and maybe we sent the request with the wrong token.
              if(e.response.headers['x-retry'] !== undefined){
                setTimeout(function(){
                    // Re-run the initial request
                    apiGetRequest(url)
                      .then((res) => resolve(res))
                      .catch(() => store.commit("addAlertNotification", serverErrorAlertObj))
                }, 300)
              }else{
                logout(true)
              }
            })
        }
        // All other errors
        else{
          // If a 403 or 404 is returned by the backend, redirect to the dashboard page
          if(e.response.status === 403 || e.response.status === 404 || e.response.status === 400){
            router.push({name: 'error_4xx', params: {statusCode: e.response.status}})
          }
          // Other errors may generate a request error (certainly a 5xx error)
          else{
            store.commit("addAlertNotification", serverErrorAlertObj)
            reject(e)
          }
        }
      })
  })
}

/**
 *
 * Send a POST request to the API. We can choose between form-data or normal json data
 * The access token will be sent as a bearer token. In case of expiration, we'll automatically send a
 * refresh token request to generate new pairs of access / refresh token according the current refresh token sent.
 *
 * In case of refresh token expiration, redirect to login page due to session expiration (inactivity)
 * In case of other error (500 server, ...), display a global alert error that is in the "AppLayout" layout
 *
 * @param url the url
 * @param data The data we want to send
 * @param isFormData To notify if we'll send form data or json data. It will use the right content-type header. False by default
 * @returns {Promise<unknown>}
 */
function apiPostRequest(url, data, isFormData = false) {
  const contentTypeHeader = isFormData ? 'multipart/form-data' : 'application/json'
  return new Promise(function(resolve, reject) {
    axios.post(url, data, {
      headers: {
        "Authorization": "Bearer " + store.state.accessToken,
        "Content-Type": contentTypeHeader
      }
    })
      // All works
      .then((res) => {
        resolve(res)
      })
      // Error
      .catch((e) => {
        // Access token expired
        if(e.response.headers['x-expired-token'] !== undefined){
          refreshTokens()
            // Successfully refreshed
            .then(() => {
              // Re-run the initial request
              apiPostRequest(url, data)
                .then((res) => resolve(res))
                .catch(() => store.commit("addAlertNotification", serverErrorAlertObj))
            })
            // Error when refreshing
            .catch(() => {
              logout(true)
            })
        }
        // All other errors
        else{
          // If a 403 or 404 is returned by the backend, redirect to the dashboard page
          if(e.response.status === 403 || e.response.status === 404){
            router.push({name: 'error_4xx', params: {statusCode: e.response.status}})
          }
          // Bad request 400 with specific formError message. Must be catch by the sended ajax request
          else if (errorResponseIsFormError(e)){
            reject(e)
          }
          // Other errors may generate a request error (certainly a 5xx error)
          else{
            store.commit("addAlertNotification", serverErrorAlertObj)
            reject(e)
          }
        }
      })
  })
}



/**
 * Send request to refresh the current access token.
 * Send the refresh token to the API. If the refresh is OK and not expired, store the new access / refresh token pair.
 * @returns {Promise<unknown>}
 */
function refreshTokens(){
  return new Promise(function(resolve, reject) {
    axios.get(API_REFRESH_TOKEN, {
      headers: {
        "Authorization": "Bearer " + store.state.refreshToken
      }
    })
      // Refresh token accepted, new pair (access / refresh) generated
      .then((res) => {
        store.commit('setTokens', res.data)
        resolve()
      })
      // Error on refresh token (server error, mal formed, expired, ...)
      .catch((e) => {
        reject(e)
      })
  })
}

/**
 * Generate a link to download CSV file.
 * To be able to download asynchronously a CSV file generated by the backend, we have to do it in the frontend.
 * We create a blob containing all data, create dynamically a <a> link that will be able to download the blob file and will simulate
 * the click on it to begin the download.
 * @param data The file data
 * @param filename The file name
 * @returns {HTMLAnchorElement} A clickable <a> link that can be used to download the file
 */
function generateDownloadCsvLink(data, filename){
  let blob = new Blob([data], { type: "text/csv" });
  let link=document.createElement('a');
  link.href=window.URL.createObjectURL(blob);
  link.download=filename;
  return link
}

function errorResponseIsFormError(e){
  return e.response.status === 400 && e.response.data.message === "formError"
}

function displayAlertSuccess(successMsg){
  store.commit('addAlertNotification', {type: AlertTypes.SUCCESS, msg: successMsg})
}
function displayAlertError(errorMsg){
  store.commit('addAlertNotification', {type: AlertTypes.DANGER, msg: errorMsg})
}

export {displayDateTimeByLocaleFromISOString, displayElapsedTimeToTimestamp, displayAlertError, displayAlertSuccess, generatePageHeadTitle, apiGetRequest, apiPostRequest, logout, displayDateByLocale, displayDateTimestampByLocale, getLetterForSensor, generateDownloadCsvLink, errorResponseIsFormError}
