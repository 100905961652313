<template>
  <nav class="nav">
      <component v-bind:is="menuByRole"/>
  </nav>
</template>

<script>
import adminMenu from './admin-menu'
import farmerMenu from './farmer-menu'
export default {
  name: "main-menu",
  components: {
    adminMenu,
    farmerMenu
  },
  computed: {
    menuByRole : function() {
      if(this.$store.getters.userIsAdmin){
        return adminMenu.name
      }else if(this.$store.getters.userIsFarmer) {
        return farmerMenu.name
      }
      // Todo: Other menu by role
      return adminMenu.name
    }
  }
}
</script>

<style>
/* Style here is not scoped. This way we can have global css for all our menus by role */

.router-link-active .menu-item, .router-link-active:hover > .menu-item {
  @apply bg-green-500 font-semibold;
}
.menu-item:hover{
  background-color: rgba(100, 100, 100, 0.5);
}
.nav {
  @apply p-6;
}
.nav .menu-item {
  @apply px-4 py-2 mb-2 text-white rounded;
}
.nav .menu-item .icon{
  @apply text-xl w-8;
}

</style>
