<template>

  <div>
    <!-- Event resume card -->
    <div class="eventCard">
      <div class="eventCard--title">
        <span class="eventCard--title__text">{{ `${$t('backend_trans_keys.' + event.transmitter.typeTransKey)} [${event.transmitter.id}]` }}</span>
        <span class="eventCard--title__subText">{{ displayDate(event.createdAt) }}</span>
      </div>

      <div class="eventCard--content text-center">
        <textarea v-model="comment" class="eventCard--content__comment" placeholder="Commentaire">
        </textarea>

        <div class="eventCard--content__btn">
          <button @click="acquitEvent" class="btn btn--primary">{{ $t('words.acquit') }} <i class="ml-2 fas fa-check"></i></button>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {apiPostRequest, displayDateByLocale} from "../../utils";
import {
  API_EVENTS_ACK_INACTIVE_TRANSMITTER_SUFFIX,
  API_EVENTS_SHOW
} from "../../utils/constants";
export default {
  name: "InactiveTransmitterEvent",
  props: {
    event: {
      type: Object,
      required: true
    },
  },
  data: function() {
    return {
      comment: null
    }
  },
  methods: {
    displayDate: function(d) {
      d = d.slice(0,-1) // Remove last millisecond entry in array to prevent moment.js date error
      return displayDateByLocale(d)
    },
    acquitEvent: function(){
      apiPostRequest(API_EVENTS_SHOW + this.event.id + API_EVENTS_ACK_INACTIVE_TRANSMITTER_SUFFIX, this.comment)
        .then(() => {
          // Emit to the parent that the event has been acquitted
          this.$emit("acquitted")
        })
    }
  }
}
</script>

<style scoped>

</style>
