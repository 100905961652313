<template>
<div>
  <div class="flex flex-row justify-between">
    <div>
      <label>{{ $t('pages.culture_show.hunt.mode-title') + " : " }}</label>
      <div>
        <select v-model="hunt.selectedCombineMode" @change="combineModeChanged" class="border-gray-200 border outline-none px-2 py-1 w-full">
          <option value="MEAN">{{ $t('pages.culture_show.hunt.mode-mean') }}</option>
          <option value="MEDIAN">{{ $t('pages.culture_show.hunt.mode-median') }}</option>
        </select>
      </div>
    </div>
  </div>

  <div class="flex flex-row justify-between mt-4">
    <div>
      <label>{{ $t('pages.culture_show.hunt.freq-title') + " : " }}</label>
      <div class="grid grid-cols-3 gap-4">
        <div class="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700" v-for="freq in hunt.allCombineFreq" :key="`freq-${freq.value}`">
          <input type="radio" :id="freq.value" :value="freq.value" v-model="hunt.selectedCombineFreq" @change="combineFreqChanged" class="w-9 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
          <label for="hourly" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ $t(freq.translateKey) }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {mapState} from "vuex";
import {apiGetRequest} from "@/utils";
import {API_CULTURES_GLOBAL_DIAMETER} from "@/utils/constants";

export default {
  name: "CombinerData",
  props:{
    cultureId:{
      type:[String, Number],
      required:true,
    },
    isHistory:{
      type:Boolean,
      required:false,
      default: false
    }
  },
  data:function(){
    return {
    }
  },
  computed:{
    ...mapState(['hunt','combined','prediction']),

  },
  mounted(){
    this.fetchCombinedData()
  },
  methods:{
    fetchCombinedData: function(){
      if(!this.combined.loadingMeasures){
        this.combined.isMeasuresLoaded = false
        this.combined.cultureChartMeasureTypes = {}
        this.combined.loadingMeasures = true
        this.prediction.predictionValues = [];
        apiGetRequest(API_CULTURES_GLOBAL_DIAMETER(this.cultureId) + `?mode=${this.hunt.selectedCombineMode}&freq=${this.hunt.selectedCombineFreq}&isHistory=${this.isHistory}`)
            .then((res)=>{
              // this.updateMeasureTypes(res.data)
              this.combined.cultureChartMeasureTypes = res.data
              this.combined.isMeasuresLoaded = true
              // this.fetchHuntParameters()

              // Set value default for prediction date
              // this.prediction.selectedPredictionDateUntil = this.defaultValuePredictionDate;
            })
            .finally(() => this.combined.loadingMeasures = false)
      }
    },
    combineModeChanged: function(){
      this.fetchCombinedData()
    },
    combineFreqChanged: function(){
      this.fetchCombinedData()

      // Reset the lastPredictSize when we change the frequency otherwise it causes issues on how many values we delete for the reference hunt curve.
      this.prediction.lastPredictSize = 0
    },
  }
}
</script>

<style scoped>

</style>