<template>
  <div class="bg-gray-50 h-screen">
    <locale-changer custom-class="bg-gray-50 absolute top-2 right-10"/>
    <slot/>
  </div>
</template>

<script>
import LocaleChanger from "../components/general/locale-changer";
export default {
  name: "PublicLayout",
  components: {LocaleChanger}
}
</script>

<style scoped>

</style>
