<template>
  <div>
    <aside class="menu-wrapper">
      <div class="bg">
        <div class="logo">
          FGAS
        </div>
        <main-menu/>
      </div>
    </aside>
    <div class="right">
      <div class="app-bar">
        <div class="page-title">{{ $t('pages.'+this.$route.name+'.title') }}</div>
        <div class="flex">
          <a href="">
            <div class="item" @click="profile">
              <i class="far fa-id-card"></i>
              <span class="item--title">{{ $t('pages.profile.title') }}</span>
            </div>
          </a>
          <div @click="signout" class="item cursor-pointer">
            <i class="fas fa-sign-out-alt"></i>
            <span class="item--title">{{ $t('pages.logout.title') }}</span>
          </div>
          <locale-changer custom-class="mt-0 ml-10 bg-white"/>
        </div>
      </div>
      <div class="main-content">
        <slot/>
      </div>
    </div>

  </div>
</template>

<script>
import MainMenu from "../components/menus/main-menu";
import localeChanger from "../components/general/locale-changer";
import {logout} from "../utils";

export default {
  name: "AppLayout",
  components: {MainMenu, localeChanger},
  methods: {
    signout: function() {
      logout()
    },
    profile:function(){
      this.$router.push({name:'profile', params:{userId:this.$store.state.user.id}}).catch(()=>{});
    }
  }
}
</script>

<style scoped>

  .menu-wrapper {
    @apply w-72 bg-black float-left fixed h-screen;
    background-image: url("../../src/assets/images/bg.png");
    background-size: cover;
  }
  .bg{
    @apply h-full;
    background-color: rgba(0,0,0,0.8);
  }
  .logo {
    @apply h-14 text-center text-xl pt-3 text-white font-bold border-b border-white;
  }


  .right {
    @apply ml-72 bg-gray-50 min-h-screen;
  }
  .right .app-bar {
    @apply px-10 h-14 text-gray-600 bg-white border-b border-gray-200 flex justify-between;
  }
  .right .app-bar .page-title {
    @apply pt-4 font-medium text-lg;
  }
  .right .app-bar .item {
    @apply px-4 pt-2 inline-block h-full text-center;
    letter-spacing: 0.235px; /* Hack to prevent moving when hover */
  }
  .right .app-bar .item:hover {
    @apply font-semibold;
    letter-spacing: 0;
  }
  .right .app-bar .item .item--title {
    @apply block -mt-2;
  }
  .right .main-content {
    @apply p-10;
    min-height: calc(100vh - 56px);
  }
</style>
