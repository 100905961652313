<template>
  <div>

    <div class="flex justify-center">
      <div class="mt-10">
        <h1 class="text-2xl mb-4 font-light text-center">{{ $t('pages.login.title') }}</h1>
        <div class="w-80 shadow-md rounded-xl bg-white">
          <div class="bg-green-500 rounded-tl-xl rounded-tr-xl h-2"></div>

          <div class="p-5">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid}">
              <form @submit.prevent="handleSubmit(submitForm)">

                <!-- Email-->
                <div class="mb-4">
                  <ValidationProvider :name="$t('fields.email')" rules="required|email" v-slot="{errors}">
                    <label class="label">{{$t('fields.email') }}</label>
                    <input v-model="form.email" class="input" type="text">
                    <p class="error">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>

                <!-- Password -->
                <div class="mb-4">
                  <ValidationProvider :name="$t('fields.password')" rules="required" v-slot="{errors}">
                    <label class="label">{{ $t('fields.password') }}</label>
                    <input v-model="form.password" id="password" class="input" type="password">
                    <p class="error">{{ errors[0] }}</p>
                    <p v-if="errorBadCred" class="error">{{ $t('validations.bad_credentials') }}</p>
                  </ValidationProvider>
                </div>

                <button class="btn w-full" :class="{ 'btn--success': !invalid,'btn--disabled': invalid }">{{ $t('pages.login.sign-in') }}</button>
              </form>
            </ValidationObserver>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import {API_LOGIN} from "../utils/constants";
import {displayAlertError} from "../utils";
export default {
  name: "Login",
  props: {
      sessionExpired: {
        type: Boolean,
        required: false
      }
  },
  data(){
    return{
      form: {
        email: '',
        password: '',
      },
      errorBadCred: false,
    }
  },
  mounted() {
    // Check if session expired
    if(this.sessionExpired){
      const errorMsg = this.$t('validations.expired_session')
      displayAlertError(errorMsg)
    }
  },
  methods: {
    submitForm() {
      this.errorBadCred = false

      let bodyFormData = new FormData()
      bodyFormData.append('email', this.form.email)
      bodyFormData.append('password', this.form.password)
      axios.post(API_LOGIN, bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((res) => {
          this.$store.commit('setTokens', res.data)
          if(this.$store.state.user.isFirstConnection){
            this.$router.push({name:'first_connection'})
          } else {
            this.$router.push('inventory')
          }
        })
        .catch((error) => {
          // Server error
          if(error.response.status === 500) {
            const errorMsg = this.$t('validations.server_error');
            displayAlertError(errorMsg)
          }
          // Auth error
          else{
            this.errorBadCred = true
            this.form.password = ''
            document.getElementById('password').focus()
            this.$refs.form.reset()
          }
        })
    }
  }
}
</script>

<style scoped>
  .input {
    @apply px-2 py-1 block w-full bg-white appearance-none text-gray-500 focus:border-gray-400 outline-none border rounded;
  }

  .label {
    @apply block font-semibold text-gray-500;
  }

  .error {
    @apply text-red-500 text-sm;
  }
</style>
