<template>
  <div>
    <label>{{ $t('components.datatable.rows-chooser.per-page') }} : </label>
    <select v-model.number="selectedEntry" @change="emitSelectedValue" class="outline-none cursor-pointer">
      <option v-for="showEntry in showEntries" :key="showEntry" :value="showEntry">{{ showEntry }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "per-page-chooser",
  props: {
    initialValue: {
      type: Number,
      required: true
    },
  },
  data: function () {
    return {
      showEntries: [10, 25, 50, 100],
      selectedEntry: this.initialValue
    }
  },
  mounted() {
    // First time we emit the event to tell the table the current selected value
    this.emitSelectedValue()
  },
  methods: {
    /**
     * Emit to the parent the current selected value
     */
    emitSelectedValue: function() {
      this.$emit('nbRowsChanged', this.selectedEntry)
    }
  }
}
</script>

<style scoped>

</style>
