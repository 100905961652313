<template>

  <div>

    <!-- Modal -> complete info -->
    <modal :visible="modalVisible" :toggle="toggleModal">

      <div class="text-center">
        <span class="eventCard--title__text">{{ `${$t('backend_trans_keys.' + event.transmitter.typeTransKey)} [${event.transmitter.id}]` }}</span>
        <span class="eventCard--title__subText">{{ displayDate(event.createdAt) }}</span>
      </div>

      <p class="mt-4 italic">{{ $t('pages.culture_events.new_transmitter-card.modal-description') }}</p>


      <p class="mt-4">{{ $t('pages.culture_events.new_transmitter-card.modal-nbSensors') }} : <b>{{ event.eventSensorItems.length }}</b></p>
      <div class="pl-6">
        <ul class="list-disc">
          <li v-for="sensorItem in event.eventSensorItems" :key="sensorItem.id">ID: {{ sensorItem.id }} ({{ $t('backend_trans_keys.'+sensorItem.newSensorTypeTransKey) }})</li>
        </ul>
      </div>

      <div class="mt-4">
        <span>{{ $t('pages.culture_events.new_transmitter-card.modal-cultureChoice') }} : </span>
        <select v-model.number="userChoice.cultureId" class="ml-2 select-basic">
          <option v-for="culture in cultures" :key="culture.id" :value="culture.id">{{ `${culture.name} (${$t('backend_trans_keys.'+culture.typeTransKey)} ${$t('backend_trans_keys.'+culture.varietyTransKey)})` }}</option>
        </select>
      </div>

      <div class="mt-4">
        <span class="mt-4">{{ $t('pages.culture_events.new_transmitter-card.modal-isReplacement') }}</span>
        <select v-model.number="userChoice.isReplacement" class="ml-2 select-basic">
          <option value="0">{{ $t('words.no') }}</option>
          <option value="1">{{ $t('words.yes') }}</option>
        </select>
      </div>

      <div v-if="userChoice.isReplacement">

        <div class="mt-4">
          <span>{{ $t('pages.culture_events.new_transmitter-card.modal-continueMeasures') }}</span>
          <select v-model.number="userChoice.continueMeasures" class="ml-2 select-basic" style="max-width: -webkit-fill-available">
            <option value="0">{{ $t('words.no') }}</option>
            <option value="1">{{ $t('words.yes') }}</option>
          </select>
        </div>

      </div>

      <div class="text-center">
        <button @click="acquitEvent" class="mt-4 btn btn--success">{{ $t('words.acquit') }} <i class="ml-2 fas fa-check"></i></button>
      </div>

    </modal>


    <!-- Event resume card -->
    <div class="eventCard" :class="{'eventCard--disabled': disabled}">
      <div class="eventCard--title">
        <span class="eventCard--title__text">{{ `${$t('backend_trans_keys.' + event.transmitter.typeTransKey)} [${event.transmitter.id}]` }}</span>
        <span class="eventCard--title__subText">{{ displayDate(event.createdAt) }}</span>
      </div>

      <div class="eventCard--content text-center">
        <p>{{ $t('pages.culture_events.new_transmitter-card.description') }}</p>
        <button @click="toggleModal" class="btn btn--primary mt-4">{{ $t('words.details') }}</button>
      </div>
    </div>

  </div>

</template>

<script>
import Modal from "../modals/modal";
import {apiGetRequest, apiPostRequest, displayDateByLocale} from "../../utils";
import {
  API_CULTURES_INDEX, API_EVENTS_ACK_NEW_TRANSMITTER_SUFFIX,
  API_EVENTS_SHOW, API_FARMERS_CULTURES
} from "../../utils/constants";
export default {
  name: "NewTransmitterReplacementEvent",
  components: {Modal},
  props: {
    event: {
      type: Object,
      required: true
    },
    userId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      modalVisible: false,
      userChoice: {
        isReplacement: 0,
        cultureId: this.event.cultureId,
        continueMeasures: 0
      },
      cultures: []
    }
  },
  methods: {
    toggleModal: function() {
      if(!this.disabled) {
        let allCulturesRoute = API_CULTURES_INDEX

        // Only Admin can specify for which user he wants all cultures
        if (this.$store.getters.userIsAdmin) {
          allCulturesRoute = API_FARMERS_CULTURES(this.userId)
        }

        // Fetch all cultures for selection
        apiGetRequest(allCulturesRoute)
            .then((res) => {
              this.cultures = res.data
              this.modalVisible = !this.modalVisible
            })
      }
    },
    displayDate: function(d) {
      d = d.slice(0,-1) // Remove last millisecond entry in array to prevent moment.js date error
      return displayDateByLocale(d)
    },
    acquitEvent: function(){
      apiPostRequest(API_EVENTS_SHOW + this.event.id + API_EVENTS_ACK_NEW_TRANSMITTER_SUFFIX, {
        "cultureId": this.userChoice.cultureId,
        "isReplacement": this.userChoice.isReplacement === 1,
        "continueMeasures": this.userChoice.continueMeasures === 1
      })
          .then(() => {
            // Emit to the parent that the event has been acquitted
            this.$emit("acquitted")
          })
          .finally(() => this.modalVisible = !this.modalVisible)
    }
  }
}
</script>

<style scoped>

</style>
