<template>
<div>
    <div class="child grid grid-cols-2">
      <div>
        <div>
          <label>{{$t('pages.culture_show.prediction.select-model')}} :</label>
          <div>
            <select
                v-model="prediction.selectedModel"
                class="border-gray-200 border outline-none px-2 py-1">
              <option v-for="model in prediction.models" :value="model.value" :key="`model-${model.value}`">{{model.name}}</option>
            </select>
            <button :disabled="isDeleteModelDisabled" :class="classDeleteModel" @click="onDeleteModelTrained"><i class="fas fa-trash icon"></i></button>
          </div>
        </div>
        <div>
          <div class="mt-4">{{$t('pages.culture_show.prediction.date-until')}} :</div>
          <!--:max="maxValuePredictionDate"-->
          <input type="datetime-local"
                 :min="defaultPredictionDate"
                 :max="maxPredictionDate"
                 v-model="prediction.selectedPredictionDateUntil"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        </div>
        <div>
          <div class="mt-4 inline-flex items-center justify-center w-full col-span-2">
            <button v-if="prediction.isLoading" class="btn btn--disabled" disabled><i class="fas fa-spinner fa-spin text-lg"></i></button>
            <button v-else class="btn btn--primary" @click="initWebSocketPrediction">{{$t('pages.culture_show.prediction.start-predict')}}</button>
          </div>
          <transition name="fade">
            <div v-if="prediction.isLoading" class="mt-4 inline-flex items-center justify-center w-full col-span-2">
              <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" :style="{'width':`${percentageCompleted}%`}">{{percentageCompleted}}%</div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

</div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {apiGetRequest, apiPostRequest, displayAlertError} from "@/utils";
import {
  API_CULTURES_MODEL,
  API_CULTURES_MODEL_DELETE,
  BASE_API_WS,
  WS_PREDICT_INFO,
  WS_PREDICT_RESULT,
  WS_PREDICT_START
} from "@/utils/constants";
import webstomp from "webstomp-client";
import {formatModel} from "@/utils/chartUtils";
import moment from "moment";

export default {
  name: "Prediction",
  props:{
    cultureId:{
      type:[Number,String],
      required:true
    },
    isHistory:{
      type:Boolean,
      required:false,
      default: false
    }
  },
  mounted(){
    this.fetchAllModels()
  },
  watch:{
    'combined.isMeasuresLoaded':{
      handler(){
        this.prediction.selectedPredictionDateUntil = this.defaultPredictionDate
      }
    }
  },
  computed:{
    ...mapState(['hunt','prediction','combined']),
    ...mapGetters(['isModelDefault','minValuePredictionDate','lastGraphDate']),
    isDeleteModelDisabled:function(){
      return this.isModelDefault(this.prediction.selectedModel)
    },
    defaultPredictionDate:function(){
      return this.minValuePredictionDate(this.lastGraphDate)
    },
    maxPredictionDate:function(){
      return this.defaultPredictionDate !== null ? moment(this.defaultPredictionDate).add(2,'weeks').format("YYYY-MM-DDTHH:mm") : ""
    },
    classDeleteModel: function(){
      return `btn ${this.isDeleteModelDisabled ? 'btn--disabled' : 'btn--danger'} w-10 ml-2`
    },
    // lastGraphDate:function(){
    //   if(this.combined.cultureChartMeasureTypes.data!==undefined && this.combined.cultureChartMeasureTypes.data!==null){
    //     let valuesLength = this.combined.cultureChartMeasureTypes.data.sensors[0].values.length
    //     return new Date(this.combined.cultureChartMeasureTypes.data.sensors[0].values[valuesLength-1].timestamp)
    //   } else if(this.combined.cultureChartMeasureTypes.sensors !==undefined && this.combined.cultureChartMeasureTypes.sensors !==null){
    //     let valuesLength = this.combined.cultureChartMeasureTypes.sensors[0].values.length
    //     return  new Date(this.combined.cultureChartMeasureTypes.sensors[0].values[valuesLength-1].timestamp)
    //   }
    //   return null
    // },
    // minValuePredictionDate:function(){
    //   let date = this.lastGraphDate
    //   return date!==null ? moment(date).add(1 + this.hunt.allCombineFreq.find(freq=>freq.value===this.hunt.selectedCombineFreq).hours,'hour').format("YYYY-MM-DDTHH:mm") : ""
    // },
  },
  data:()=>{
    return{
      percentageCompleted:0,
      predictionColor:'green',
    }
  },
  methods:{
    onDeleteModelTrained:function(){
      if(this.prediction.selectedModel!==null&&this.prediction.selectedModel!==undefined && !this.isModelDefault(this.prediction.selectedModel)){
        apiPostRequest(API_CULTURES_MODEL_DELETE(this.prediction.selectedModel),undefined)
            .then(()=>{
              this.prediction.models = this.prediction.models.filter(m=>m.value!==this.prediction.selectedModel)
              this.prediction.selectedModel = this.prediction.defaultModels[0].value
            })
      }
    },
    initWebSocketPrediction:function(){
      if((this.$store.state.user.id!==undefined&&this.$store.state.user.id!==null)&&(this.cultureId!==undefined&&this.cultureId!==null))
      {
        this.percentageCompleted = 0;
        this.prediction.isLoading = true;
        this.prediction.isMeasuresLoaded=false;
        const onDisconnect = ()=>{this.prediction.isLoading=false;}
        const client = webstomp.over(new WebSocket(BASE_API_WS),{debug:false})
        client.connect({}, ()=>{
          client.subscribe(WS_PREDICT_INFO,message=>{
            const data = JSON.parse(message.body)
            if(data.status!=="STARTED"){
              displayAlertError(this.$t('pages.culture_show.prediction.error-predict'));
              client.disconnect(onDisconnect)
            }
          })
          client.subscribe(WS_PREDICT_RESULT,message=>{
            const data = JSON.parse(message.body)
            // noinspection JSUnresolvedVariable
            this.percentageCompleted = Math.round(data.result.stepPredicted/data.result.stepTotal * 100)
            if(data.status==="FINISHED"){
              this.prediction.predictionValues = [];
              this.combined.cultureChartMeasureTypes = 'data' in this.combined.cultureChartMeasureTypes  ? this.combined.cultureChartMeasureTypes.data : this.combined.cultureChartMeasureTypes
              this.prediction.predictionValues = data.chart.sensors[0].values

              let values = {id:this.prediction.selectedModel,typeTransKey:"prediction",values:this.prediction.predictionValues, color:this.predictionColor}
              if(this.combined.cultureChartMeasureTypes.sensors.length > 5){
                this.combined.cultureChartMeasureTypes.sensors[5] = values
              }else{
                this.combined.cultureChartMeasureTypes.sensors.push(values)
              }

              // this.updateMeasureTypes(this.hunt.measureTypes)

              this.hunt.isMeasuresLoadedHunt = false
              this.hunt.loadingMeasuresHunt = true

              this.prediction.isLoading = false;
              this.prediction.isMeasuresLoaded = true
              client.disconnect(onDisconnect)
              document.getElementById('culture-chart').scrollIntoView()
            } else if(data.status==="ERROR"){
              displayAlertError(this.$t('pages.culture_show.prediction.error-predict'));
              client.disconnect(onDisconnect)
            }
          })
          // noinspection JSUnresolvedVariable
          console.log(`before sending ${this.isHistory}`)
          client.send(
              WS_PREDICT_START,
              JSON.stringify({
                cultureId:this.cultureId,
                userId:this.$store.state.user.id,
                model: this.isModelDefault(this.prediction.selectedModel) ? this.prediction.selectedModel : "OTHER",
                start: this.minValuePredictionDate,
                until: this.prediction.selectedPredictionDateUntil,
                freq: this.hunt.selectedCombineFreq,
                idModel: this.isModelDefault(this.prediction.selectedModel) ? null: this.prediction.selectedModel,
                history:this.isHistory
              })
          )
        })
      }
    },
    fetchAllModels: function(){
      apiGetRequest(API_CULTURES_MODEL(this.cultureId))
          .then((res)=>{
            if(res.data!==null&&res.data!==undefined){
              this.prediction.models = [...this.prediction.defaultModels, ...res.data.map(formatModel)]
            }
          })
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active{
  transition: opacity 1s ease;
}
.fade-enter-to
.fade-leave{
  opacity: 1;
}
.fade-enter,
.fade-leave-to{
  opacity:0;
}
</style>