<template>
  <div class="text-right">
    <div class="grid grid-cols-6">
      <h2 class="text-right col-span-5 card__title">
        {{$t('pages.culture_show.prediction.training-parameters')}}
      </h2>
      <div>
        <button v-if="!showParametersFitPrediction" class="rounded-full h-7 w-7 border-green-500 border-2 hover:bg-green-50" @click="switchParametersFitPrediction"><i class="fa fa-solid fa-plus text-green-500"></i></button>
        <button v-else class="rounded-full h-7 w-7 border-red-500 border-2 hover:bg-red-50" @click="switchParametersFitPrediction"><i class="fa fa-solid fa-minus text-red-500"></i></button>
      </div>
    </div>
    <div v-if="showParametersFitPrediction">
      <label for="fit-name">{{$t('pages.culture_show.prediction.fit-name')}} :</label><input type="text" id="fit-name" class="ml-2 border-2" v-model="nameFitModel"/>
      <div class="group relative">
                    <span class="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 text-center rounded-md absolute
                         translate-y-full opacity-0 mt-6 p-2">{{$t('pages.culture_show.prediction.fit-previous-hours-tooltip')}}</span>
        <label for="fit-previous-hours">{{$t('pages.culture_show.prediction.fit-previous-hours')}} :</label>
        <input type="number" id="fit-previous-hours" class="ml-2 border-2" v-model="previousHoursForPrediction" :min="previousHoursForPredictionMin" :max="previousHoursForPredictionMax"/>
      </div>
      <div class="text-left ml-10 mt-2 mb-2">{{$t('pages.culture_show.prediction.select-fit-variables')}} :</div>
      <div class="grid grid-cols-3 text-center">
        <div v-for="variable in possibleUsedVariables" :key="variable.value">
          <label class="mr-2" :for="variable.value">{{$t(`backend_trans_keys.${variable.translateKey}`)}}</label>
          <input type="checkbox" :id="variable.value" :value="variable.value" v-model="checkedUsedVariables" :disabled="variable.disabled">
        </div>
        <div v-for="variable in complexPossibleUsedVariables" :key="variable.value">
          <label class="mr-2" :for="variable.value">{{variable.name}}</label>
          <input type="checkbox" :id="variable.value" :value="variable.value" v-model="checkedComplexUsedVariables" @change="checkDependentVariables">
        </div>
      </div>
      <div class="text-center mt-4">
        <button v-if="predictionModelIsFitting" class="btn btn--disabled" disabled><i class="fas fa-spinner fa-spin text-lg"></i></button>
        <div v-else class="group relative w-full">
                    <span v-if="hasMaxNumberOfModelsTrained" class="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-gray-100 text-center rounded-md absolute left-1/2 transform -translate-x-1/2
                         translate-y-1/2 opacity-0 p-2 z-50">{{$t('pages.culture_show.prediction.fit-has-too-many-models-tooltip')}}</span>
          <button  :class="classFitPredictionModel" @click="initWebSocketFit" :disabled="hasMaxNumberOfModelsTrained">{{$t('pages.culture_show.prediction.fit')}}</button>
        </div>
      </div>
      <div v-if="predictionModelIsFitting" class="text-center">{{this.formatFitTimer()}}</div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {BASE_API_WS, N_MAX_MODEL_TRAINED, WS_FIT_INFO, WS_FIT_RESULT, WS_FIT_START} from "@/utils/constants";
import webstomp from "webstomp-client";
import {displayAlertError} from "@/utils";
import {formatModel} from "@/utils/chartUtils";

export default {
  name: "FitModel",
  props:{
    cultureId:{
      type:[Number,String],
      required:true
    },
    isHistory:{
      type:Boolean,
      required:false,
      default: false
    }
  },
  computed:{
    ...mapState(['hunt','prediction']),
    ...mapGetters(['isModelDefault']),
    hasMaxNumberOfModelsTrained: function(){
      return this.prediction.models.filter(m=>!this.isModelDefault(m.value)).length >= N_MAX_MODEL_TRAINED
    },
    classFitPredictionModel:function(){
      return `btn ${this.hasMaxNumberOfModelsTrained ? 'btn--disabled' : 'btn--primary'}`
    },
  },
  watch:{
    fitModelTimer: {
      handler(value){
        if(value>0&&this.predictionModelIsFitting){
          setTimeout(()=>{
            this.fitModelTimer--;
          },1000)
        }
      },
      immediate:true
    }
  },
  data:()=>{
    return{
      showParametersFitPrediction:false,
      nameFitModel:'',
      previousHoursForPrediction:48,
      previousHoursForPredictionMin:12,
      previousHoursForPredictionMax:96,
      predictionModelIsFitting:false,
      possibleUsedVariables:[
        {value:'TEMPERATURE',translateKey:'measureType_temperature', disabled:false},
        {value:'HUMIDITY',translateKey:'measureType_humidity', disabled:false},
        // {value:'PRESSURE',translateKey:'measureType_pressure', disabled:false},
        // {value:'WIND_SPEED',translateKey:'measureType_wind-speed', disabled:false},
        // {value:'IRRADIANCE',translateKey:'measureType_irradiance', disabled:false},
      ],
      complexPossibleUsedVariables:[
        // {value:'VPD', name:'VPD', dependentVariables:['TEMPERATURE','HUMIDITY']},
        // {value:'ET_MAKKINK', name:'ET (Makkink)', dependentVariables:['TEMPERATURE','PRESSURE','IRRADIANCE']}
      ],
      checkedComplexUsedVariables:[],
      checkedUsedVariables:[],
      fitModelTimer:0,
    }
  },
  methods:{
    switchParametersFitPrediction:function(){
      this.showParametersFitPrediction = ! this.showParametersFitPrediction
    },
    formatFitTimer: function(){
      if(this.fitModelTimer<6){
        return `<5s ${this.$t('pages.culture_show.prediction.fit-timer')}`
      }
      return `~${this.fitModelTimer}s ${this.$t('pages.culture_show.prediction.fit-timer')}`
    },
    checkDependentVariables:function(event){
      if(!event.target.checked){
        this.complexPossibleUsedVariables.find(cv=>cv.value===event.target.value).dependentVariables.forEach(v=>this.possibleUsedVariables.find(puv=>puv.value===v).disabled=false)
      } else {
        this.complexPossibleUsedVariables.find(cv=>cv.value===event.target.value).dependentVariables.forEach(v=>this.possibleUsedVariables.find(puv=>puv.value===v).disabled=true)
      }
      this.checkedComplexUsedVariables
          .flatMap(v=>this.complexPossibleUsedVariables.find(cv=>cv.value===v).dependentVariables)
          .filter(v=>!this.checkedUsedVariables.includes(v))
          .forEach(v=>this.checkedUsedVariables.push(v))
    },
    initWebSocketFit : function () {
      // noinspection JSUnresolvedVariable
      if ((this.$store.state.user.id !== undefined && this.$store.state.user.id !== null) && (this.cultureId !== undefined && this.cultureId !== null)) {
        this.predictionModelIsFitting = true;
        const onDisconnect = ()=>{this.predictionModelIsFitting=false;}
        const client = webstomp.over(new WebSocket(BASE_API_WS),{debug:false})
        client.connect({},()=>{
          client.subscribe(WS_FIT_INFO,message=>{
            const data = JSON.parse(message.body)
            if(data.status!=="STARTED"){
              displayAlertError(this.$t('pages.culture_show.prediction.error-fitting'));
              client.disconnect(onDisconnect)
            }
          })
          client.subscribe(WS_FIT_RESULT,message=>{
            const data = JSON.parse(message.body)
            if(data.status==="ONGOING"){
              // noinspection JSUnresolvedVariable
              this.fitModelTimer = data.estimatedTimeFitInSec
            }
            else if(data.status==="FINISHED"){
              const model = formatModel(data.model)
              this.prediction.models.push(model)
              this.prediction.selectedModel = model.value
              client.disconnect(onDisconnect)
            } else if(data.status==="ERROR"){
              displayAlertError(this.$t('pages.culture_show.prediction.error-fitting'));
              client.disconnect(onDisconnect)
            }
          })
          // console.log('Subscribing now sending')
          client.send(
              WS_FIT_START,
              JSON.stringify({
                cultureId:this.cultureId,
                userId:this.$store.state.user.id,
                usedVariables:this.checkedUsedVariables,
                features:this.checkedComplexUsedVariables,
                name:this.nameFitModel,
                hoursForPrediction:this.previousHoursForPrediction,
                history:this.isHistory
              })
          )
        })
      }
    },
  }
}
</script>

<style scoped>

</style>