<template>
  <div class="page-container">
    <div v-if="culture.isLoaded" class="h-full flex">

      <!-- Map part -->
      <div class="card w-2/3">
        <interactive-map ref="map" :isGeoMap="culture.data.hasGeoMap" :transmitters="culture.data.transmitters" :mapImageName="culture.data.mapImageName" :editionMode="true">
          <template v-slot:customControl>
            <div class="shadow-md text-right bg-white py-4 px-10 border border-gray-300 rounded-md">
              <h1 class="text-lg">{{ $t('pages.culture_map_edit.map.customControl.title') }} <b class="text-md">{{ culture.data.name }}</b></h1>
              <h2 class="text-sm">
                {{ $t('pages.culture_map_edit.map.customControl.subtitle') }} : {{ nbTransmittersPlaced }}/{{ culture.data.transmitters.length }}
                <i v-if="nbTransmittersPlaced !== culture.data.transmitters.length" class="ml-1 fas fa-exclamation-triangle text-yellow-400"></i>
                <i v-else class="ml-1 fas fa-check text-green-400"></i>
              </h2>
            </div>
          </template>
        </interactive-map>
      </div>

      <!-- Transmitter part -->
      <div class="flex flex-col card w-1/3 ml-6">
        <!-- Title -->
        <div>
          <h1 class="text-center text-lg">{{ $t('pages.culture_map_edit.map.title', {'count': culture.data.transmitters.length}) }}</h1>
        </div>
        <!-- Transmitters list -->
        <div v-if="culture.data.transmitters.length > 0" class="h-full overflow-y-auto px-2 my-3">
          <div @click="toggleTransmitter(t)" v-for="t in culture.data.transmitters" :key="t.id" class="transmitter-list-item flex justify-between items-center">
            <span class="">{{ t.id }} - <span class="font-light">{{ $t("backend_trans_keys."+t.typeTransKey) }}</span></span>
            <span v-if="t.latitude === null && t.longitude === null" class="text-green-500 font-semibold"><i class="fas fa-plus mr-2"></i>{{ $t('pages.culture_map_edit.map.addToMap') }}</span>
            <span v-else class="text-red-500 font-semibold"><i class="fas fa-minus mr-2"></i>{{ $t('pages.culture_map_edit.map.removeFromMap') }}</span>
          </div>
        </div>
        <div v-else class="mt-6 italic text-center">
          <span>{{ $t('pages.culture_map_edit.map.noTransmitter') }}</span>
        </div>
        <!-- Validate -->
        <div class="text-center">
          <button v-if="modifiedTransmitters.length > 0" @click="onValidateClicked" class="btn btn--success w-1/2"><i class="fas fa-check mr-2"></i>{{ $t('pages.culture_map_edit.map.validate') }}</button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {apiGetRequest, apiPostRequest, displayAlertSuccess} from "../../../utils";
import {API_CULTURES_SHOW2, API_TRANSMITTERS_COORDS_UPDATE} from "../../../utils/constants";
import InteractiveMap from "../../../components/interactive-map/interactive-map";

export default {
  name: "CultureMapEdit",
  components: {InteractiveMap},
  props: {},
  data: function() {
    return {
      id: this.$route.params.id,
      culture: {
        isLoaded: false,
        data: null
      },
      initialTransmitters: null
    }
  },
  mounted() {
    this.loadCulture()
  },
  computed: {
    /**
     * Return the number of transmitters that are currently placed in the map (lat, lng not null)
     * @returns {number} The number of transmitters currently on the map
     */
    nbTransmittersPlaced: function() {
      return this.culture.data.transmitters.filter(t => t.latitude !== null && t.longitude !== null).length
    },
    /**
     * Return an array containing all transmitters that have a modified position (lat or lng) compared to their initial pos
     * @returns {T[]} All modified transmitters
     */
    modifiedTransmitters: function(){
      return this.culture.data.transmitters.filter((t, i) => t.latitude !== this.initialTransmitters[i].latitude || t.longitude !== this.initialTransmitters[i].longitude)
    }
  },
  methods: {
    /**
     * Load culture info
     */
    loadCulture: function() {
      this.culture.isLoaded = false

      // Fetch all culture info
      apiGetRequest(API_CULTURES_SHOW2(this.id))
          .then((res) => {
            this.culture.data = res.data

            // Redirect to the culture show page if the culture doesn't have any map.
            // E.g someone type directly the URL (cultures/42/map/edit) but the cultures 42 doesn't have any map (hasGeoMap === null)
            if(this.culture.data.hasGeoMap !== null){
              // Create a full deep copy of the array to keep the initial state
              this.initialTransmitters = this.culture.data.transmitters.map(t => Object.assign({}, t));
              this.culture.isLoaded = true
            }else{
              this.$router.push({name: "culture_show", params: {id: this.id}})
            }
          })
    },
    /**
     * Update the lat / lng of the clicked transmitter to let him appear or disappear on the map
     * @param t The transmitter we clicked on in the list
     */
    toggleTransmitter: function(t) {
      // Pass by the ref of the <interactive-map> inside this view and also the one in the child view to be able to inspect the real Leaflet Map object
      const center = this.$refs.map.$refs.map.mapObject.getCenter()

      if(t.latitude !== null && t.longitude !== null){
        t.latitude = null
        t.longitude = null
      }else{
        t.latitude = center.lat
        t.longitude = center.lng
      }
    },
    /**
     * Send to the backend a POST request with only the transmitters that have a position update (lat or lng)
     */
    onValidateClicked: function() {
      const data = this.modifiedTransmitters.map(t => {
        return {
          id: t.id,
          latitude: t.latitude,
          longitude: t.longitude
        }
      })

      apiPostRequest(API_TRANSMITTERS_COORDS_UPDATE, {
        coordsTransmitters: data
      })
          .then(() => {
            const successMsg = this.$t('pages.culture_map_edit.map.success', {'name': this.culture.data.name})
            displayAlertSuccess(successMsg)
            this.$router.push({name: "culture_show", params: {id: this.id}})
          })
    }
  }
}
</script>

<style scoped>
  .page-container {
    height: calc(100vh - 136px);
  }

  .transmitter-list-item {
    @apply cursor-pointer border rounded-md my-2 py-2 px-4;
  }

  .transmitter-list-item:hover {
    box-shadow: 0 0 0 2px #059669;
  }

</style>
