<template>
  <modal :toggle="toggle" :visible="visible">
    <h1 class="font-semibold mb-2">{{ $t('components.confirm-modal.title') }}</h1>
    <slot></slot>
    <div class="mt-4 flex justify-end">
      <button @click="cancelClicked" class="btn btn--gray">{{ $t('components.confirm-modal.cancel') }}</button>
      <button @click="yesClicked" class="ml-4 btn btn--danger">{{ $t('components.confirm-modal.yes') }}</button>
    </div>
  </modal>
</template>

<script>
import modal from "./modal";
export default {
  name: "confirm-modal",
  components: {modal},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    toggle: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: false
    },
  },
  methods: {
    cancelClicked: function(){
      this.$emit("cancel")
    },
    yesClicked: function() {
      this.$emit("yes")
    }
  }
}
</script>

<style scoped>

</style>
