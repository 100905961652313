<template>
  <div class="p-5">
    <ValidationObserver ref="formChangePassword" v-slot="{ handleSubmit, invalid}">
      <form @submit.prevent="handleSubmit(submitFormChangePassword)">
        <!-- Password -->
        <div class="mb-4">
          <ValidationProvider :name="$t('fields.password').toString()" rules="required" v-slot="{errors}">
            <label for="password" class="form__label">{{ $t('fields.password') }}</label>
            <input @change="updatePasswordErrors" v-model="form.password" id="password" class="form__input w-full" type="password">
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <!-- Confirm Password -->
        <div class="mb-4">
          <ValidationProvider :name="$t('fields.confirmPassword').toString()" rules="required" v-slot="{errors}">
            <label for="confirmPassword" class="form__label">{{ $t('fields.confirmPassword') }}</label>
            <input v-model="form.confirmPassword" id="confirmPassword" class="form__input w-full" type="password">
            <p v-if="passwordErrors.length>0" class="form__error"><ul><li v-for="(error, index) in passwordErrors" :key="index">{{error}}</li></ul></p>
            <p class="form__error">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>

        <button class="btn w-full" :class="{ 'btn--success': !invalid && passwordErrors.length===0,'btn--disabled': invalid || passwordErrors.length>0}">{{ $t('pages.change_password.modify') }}</button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import {apiPostRequest, displayAlertSuccess} from "@/utils";
import {API_USERS_CHANGE_PASSWORD} from "@/utils/constants";

export default {
  name: "ChangePassword",
  props:{
    redirectName:{
      type:String,
      required:false
    },
    userId:{
      type:[String, Number],
      required:true
    }
  },
  data(){
    return{
      form: {
        password: '',
        confirmPassword: '',
      },
      passwordErrors: []
    }
  },
  methods:{
    submitFormChangePassword:function(){
      if(this.form.password===this.form.confirmPassword){
        if(this.passwordErrors.length===0){
          apiPostRequest(API_USERS_CHANGE_PASSWORD(this.userId),{password: this.form.password})
              .then(()=>{
                displayAlertSuccess(this.$t('pages.change_password.success'))
                if(this.redirectName!==undefined && this.redirectName!==null && this.redirectName.length>0){
                  this.$router.push({name:this.redirectName})
                }
              })
              .catch((e)=>{
                console.log(e)
              })
        }
      } else {
        const errors = {}
        errors[this.$t('fields.confirmPassword')] = this.$t("pages.change_password.not-same")
        this.$refs.formChangePassword.setErrors(errors)
        document.getElementById("password").focus()
      }
    },
    updatePasswordErrors:function(){
      this.passwordErrors = []
      // Check for length
      if (this.form.password.length < 8) {
        this.passwordErrors.push(this.$t("pages.change_password.error.longer"));
      }
      // Check for lower
      if (!this.form.password.match(/[a-z]/)) {
        this.passwordErrors.push(this.$t("pages.change_password.error.lower"));
      }
      // Check for lower
      if(!this.form.password.match(/[A-Z]/)){
        this.passwordErrors.push(this.$t("pages.change_password.error.upper"));
      }
      // Check for numbers
      if (!this.form.password.match(/\d/)) {
        this.passwordErrors.push(this.$t("pages.change_password.error.digit"));
      }
      // Check for special characters
      if (!this.form.password.match(/[^a-zA-Z\d]/)) {
        this.passwordErrors.push(this.$t("pages.change_password.error.special"));
      }
      return this.passwordErrors.length === 0
    }
  }
}
</script>

<style scoped>

</style>