<template>
  <l-marker :lat-lng="[transmitter.latitude, transmitter.longitude]" :draggable="draggable" @click="onClickTransmitter(transmitter.id)" @update:latLng="onDragTransmitter(transmitter, $event)">

    <!-- Popup -->
    <l-popup class="w-auto">
      <!-- Title -->
      <router-link :to="{name: 'transmitter_show', params: {id: transmitter.id}}">
        <h1 class="text-lg">{{ $t('backend_trans_keys.'+transmitter.typeTransKey) }} <b>{{ transmitter.id }}</b></h1>
      </router-link>
      <!-- Battery level -->
      <div class="flex">
        <div class="w-32 h-3">
          <battery-indicator :level="batteryLevelPercent" :displayPercent="true"/>
        </div>
        <div v-if="transmitter.batteryPct !== null" class="w-full ml-2 -mt-0.5">{{ $t('pages.culture_show.interactiveMap.markerTransmitter.receivedOn') }} {{ displayDateTimeByTimestamp(transmitter.lastBatteryAt) }}</div>
      </div>
      <!-- Last seen / measure at-->
      <div class="text-sm mt-2 flex justify-items-start">
        <div>
          <div>{{ $t('pages.culture_show.interactiveMap.markerTransmitter.lastMeasureAt') }}: </div>
          <div>{{ $t('pages.culture_show.interactiveMap.markerTransmitter.lastActivityAt') }}: </div>
        </div>
        <div class="ml-4 font-semibold">
          <div>{{ lastMeasureAtElapsed }}</div>
          <div>{{ lastSeenAtElapsed }}</div>
        </div>
      </div>
      <!-- Sensors -->
      <div class="mt-2 text-sm">
        <!-- loader -->
        <div v-if="!sensors.isLoaded" class="text-center">
          <h2 class="text-left text-base underline">{{ $t('pages.culture_show.interactiveMap.markerTransmitter.sensors.title') }}</h2>
          <i class="mt-1 text-lg fas fa-spinner fa-spin"></i>
        </div>
        <!-- data -->
        <div v-else>
          <h2 class="text-base underline">{{ $t('pages.culture_show.interactiveMap.markerTransmitter.sensors.title') }} ({{ sensors.data.length }})</h2>
          <ul v-if="sensors.data.length > 0">
            <router-link v-for="sensor in sensors.data" :key="sensor.id" :to="{name: 'sensor_show', params: {id: sensor.id}}">
              <li class="ml-6 list-disc">{{ sensor.id }} - {{ $t('backend_trans_keys.' + sensor.typeTransKey) }}</li>
            </router-link>
          </ul>
          <span v-else class="italic">
            {{ $t('pages.culture_show.interactiveMap.markerTransmitter.sensors.none') }}
          </span>
        </div>
      </div>
    </l-popup>

    <!-- Custom marker icon -->
    <l-icon :icon-anchor="anchor" :popupAnchor="popupAnchor">
      <div class="min-w-min bg-white py-2 px-3 border border-gray-600 rounded-md shadow-md">
        <!-- Our different icons (simple transmitter / weather station ) have more or less the same ratio, so the size / anchor are the same -->
        <img :style="{ width: size[0] + 'px', height: size[1] + 'px' }"  class="mx-auto" :src="transmitter.weatherStation ? iconPaths.weatherStation : iconPaths.simpleTransmitter">
        <div class="text-lg font-bold text-center">{{ transmitter.id }}</div>
        <!-- battery level (if defined) -->
        <div v-if="batteryLevelPercent !== -1" class="w-full h-2">
          <battery-indicator :level="batteryLevelPercent"/>
        </div>
      </div>
    </l-icon>

  </l-marker>
</template>

<script>
import {LIcon, LMarker, LPopup} from "vue2-leaflet";
import BatteryIndicator from "./battery-indicator";
import {apiGetRequest, displayDateTimestampByLocale, displayElapsedTimeToTimestamp} from "../../utils";
import {API_TRANSMITTERS_SHOW2} from "../../utils/constants";

export default {
  name: "MarkerTransmitter",
  components: {
    BatteryIndicator,
    LMarker,
    LIcon,
    LPopup
  },
  props: {
    transmitter: {
      type: Object,
      required: true
    },
      draggable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      size: [35, 39],
      iconPaths: {
        simpleTransmitter: '/static/images/simple-transmitter_icon.png',
        weatherStation: '/static/images/weather-station_icon.png'
      },
      sensors: {
        isLoaded: false,
        data: null
      }
    }
  },
  mounted() {},
  methods: {
    onDragTransmitter: function(transmitter, coords) {
      transmitter.latitude = coords.lat
      transmitter.longitude = coords.lng
    },
    onClickTransmitter: function(transmitterId) {
      // Load sensors if not already done
      if(this.sensors.data === null){
        apiGetRequest(API_TRANSMITTERS_SHOW2(transmitterId))
            .then((res) => {
              this.sensors.data = res.data.sensors
              this.sensors.isLoaded = true
            })
      }
    },
    /**
     * Display date by timestamp using moment.js locale
     * @param dateTimeTimestamp The date to display in timestamp
     * @returns {string} A string representation of the date, well formatted by the current locale
     */
    displayDateTimeByTimestamp: function(dateTimeTimestamp) {
      return displayDateTimestampByLocale(dateTimeTimestamp)
    },
  },
  computed: {
    anchor: function() {
      return [this.size[0] / 2 + 16, this.size[1] / 2 + 28] // To be more or less at the center of the full marker box
    },
    popupAnchor: function() {
      return [0, -this.size[1]] // To be just above the icon
    },
    /**
     * Return rounded level percent value of the transmitter battery
     * @returns {number} [0 - 100] if defined, -1 otherwise
     */
    batteryLevelPercent: function(){
      if(this.transmitter.batteryPct > 100){
        return 100
      }else if(this.transmitter.batteryPct < 0 ) {
        return 0
      }else if(this.transmitter.batteryPct === null){
        return -1
      }else{
        return Math.round(this.transmitter.batteryPct)
      }
    },
    lastMeasureAtElapsed: function() {
      return this.transmitter.lastMeasureAt == null ? this.$t('words.never') : displayElapsedTimeToTimestamp(this.transmitter.lastMeasureAt)
    },
    lastSeenAtElapsed: function() {
      return this.transmitter.lastSeenAt == null ? this.$t('words.never') : displayElapsedTimeToTimestamp(this.transmitter.lastSeenAt)
    }
  }
}
</script>

<style scoped>
  a:hover {
    @apply underline;
  }
</style>
