<template>
  <div>
  <div :class="showHiddenChart" v-if="!missingData">
    <div class="card mt-4">
    <h2 class="card__title">{{ $t('pages.culture_show.hunt.settings-title')}}</h2>
    <HuntCurve :culture-id="cultureId" :is-history="isHistory"/>
    <PredictionCurve :culture-id="cultureId" :is-history="isHistory"/>
    </div>
    <div v-if="hunt.isMeasuresLoadedHunt">
      <div class="card mt-4">
        <div v-for="measureType in measureTypes" :key="`measure-${measureType.id}`" class="card mt-5">
          <h2 class="card__title">{{ $t('backend_trans_keys.'+measureType.typeTransKey) }}</h2>
          <div class="overflow-x-auto">
            <div id="culture-chart" style="min-width: 600px">
              <highcharts :options="measureType.chartOptions" ref="lineCharts" constructor-type="chart"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading || missingData">
    <p v-if="missingData" class="m-5 text-center w-full font-thin">{{$t('pages.culture_show.hunt.error-missing')}}</p>
    <p v-else class="m-5 text-center w-full"><i class="fas fa-spinner fa-spin"></i></p>
  </div>
  </div>
</template>
<script>
import HuntCurve from "@/components/model/HuntCurve.vue";
import {mapState} from "vuex";
import {getLineChartOptionsByMeasureType} from "@/utils/chartUtils";
import {displayAlertError} from "@/utils";
import PredictionCurve from "@/components/model/PredictionCurve.vue";
import store from "@/store";

export default {
  name: "CultureChart",
  components: {PredictionCurve, HuntCurve},
  props:{
    cultureId:{
      type:[String,Number],
      required:true
    },
    isHistory:{
      type:Boolean,
      required:false,
      default: false
    }
  },
  mounted(){
    store.commit({type:'resetAllState'})
  },
  computed:{
    ...mapState(['hunt','combined']),
    showHiddenChart: function(){
      return this.isLoading ? 'hidden' : ''
    }
  },
  data:function(){
    return{
      measureTypes:{},
      isLoading:true,
      missingData:false,
    }
  },
  watch:{
    'combined.cultureChartMeasureTypes':{
      handler(data){
        if(data!==null&&data!==undefined && data.sensors!==undefined && data.sensors.length>0){
          // this.updateMeasureTypes(data)
          this.isLoading=false;
          this.updateMeasureTypes(data)
        }
      }
    }
  },
  methods:{
    updateMeasureTypes: function(data) {
      this.measureTypes =  {data}
      for (const key in this.measureTypes) {
        const measureType = this.measureTypes[key]
        // Error may be possible when chart export, if we have an old browser
        measureType.sensors[0].id = this.$t(`pages.culture_show.hunt.mode-${this.hunt.selectedCombineMode.toLowerCase()}`)
        measureType["chartOptions"] = getLineChartOptionsByMeasureType(measureType, () => {
          const errorMsg = this.$t('pages.sensor_show.error-export')
          displayAlertError(errorMsg)
        })
      }
      this.missingData = this.measureTypes.data.sensors===undefined || this.measureTypes.data.sensors[0].values === undefined || this.measureTypes.data.sensors[0].values.length === 0
      // console.log(this.missingData, ' missing')
      // console.log(this.isLoading, ' loading')
    },
  }
}
</script>

<style scoped>

</style>