<template>
  <div>

    <!-- Modal -> complete info -->
    <modal v-if="userChoice !== null" :visible="modalVisible" :toggle="toggleModal">

      <div class="text-center">
        <span class="eventCard--title__text">{{ `${$t('backend_trans_keys.' + event.transmitter.typeTransKey)} [${event.transmitter.id}]` }}</span>
        <span class="eventCard--title__subText">{{ displayDate(event.createdAt) }}</span>
      </div>

      <p class="my-4 italic">{{ $t('pages.culture_events.sensor_management-card.modal-description') }}</p>


      <!-- Removed sensor items -->
      <div v-if="this.removedSensorItems.length">
        <h2 class="underline font-semibold">{{ this.$t('pages.culture_events.sensor_management-card.removed-sensors') }}</h2>
        <div v-for="(removedSensorItem, i) in this.removedSensorItems" :key="removedSensorItem.id">
          <div>
            <span>{{ $t('backend_trans_keys.'+removedSensorItem.oldSensorTypeTransKey) }} : {{ removedSensorItem.oldSensorId }} </span>
            <span v-if="getLetter(removedSensorItem.oldSensorId) !== '-'">({{ getLetter(removedSensorItem.oldSensorId) }})</span>
            <textarea v-model="userChoice.removedSensorItems[i].comment" class="eventCard--content__comment" :placeholder="$t('words.comment')"></textarea>
          </div>
        </div>
      </div>


      <!-- Added sensor items -->
      <div v-if="this.addedSensorItems.length">
        <h2 class="mt-8 underline font-semibold">{{ this.$t('pages.culture_events.sensor_management-card.added-sensors') }}</h2>
        <div :class="{'mb-6': removedSensorItems.length}" v-for="(addedSensorItem, i) in this.addedSensorItems" :key="addedSensorItem.id">
          <span>{{ $t('backend_trans_keys.'+addedSensorItem.newSensorTypeTransKey) }} : {{ addedSensorItem.newSensorId }} </span>
          <span v-if="getLetter(addedSensorItem.newSensorId) !== '-'">({{ getLetter(addedSensorItem.newSensorId) }})</span>
          <textarea v-model="userChoice.addedSensorItems[i].comment" class="eventCard--content__comment" :placeholder="$t('words.comment')"></textarea>

          <!-- Can be a replacement, only if there is some sensor removed -->
          <div v-if="removedSensorItems.length">
            <span class="font-thin">{{ $t('pages.culture_events.sensor_management-card.modal-isReplacement') }} </span>
            <select v-model.number="userChoice.addedSensorItems[i].isReplacement" class="select-basic">
              <option value="0">{{ $t('words.no') }}</option>
              <option value="1">{{ $t('words.yes') }}</option>
            </select>

            <!-- Select the sensor replacement / continue measurement only if user says that is a replacement -->
            <div v-if="userChoice.addedSensorItems[i].isReplacement === 1">
              <div>
                <label class="font-thin">{{ $t('pages.culture_events.sensor_management-card.modal-replacement') }}: </label>
                <select v-model.number="userChoice.addedSensorItems[i].sensorItemReplacementId" class="mt-1 select-basic">
                  <option v-for="removedSensorItem in removedSensorItems" :key="removedSensorItem.id" :value="removedSensorItem.id">{{ `${removedSensorItem.oldSensorId}` }}</option>
                </select>
              </div>
              <div>
                <span class="font-thin">{{ $t('pages.culture_events.sensor_management-card.modal-continueMeasurement') }} </span>
                <select v-model.number="userChoice.addedSensorItems[i].continueMeasurement" class="select-basic">
                  <option value="0">{{ $t('words.no') }}</option>
                  <option value="1">{{ $t('words.yes') }}</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="text-center">
        <span v-if="disabled">{{ $t('pages.culture_events.sensor_management-card.modal-previousAckBefore') }}</span>
        <button v-else @click="acquitEvent" class="mt-4 btn btn--success">{{ $t('words.acquit') }} <i class="ml-2 fas fa-check"></i></button>
      </div>

    </modal>

    <!-- Event resume card -->
    <div class="eventCard">
      <div class="eventCard--title">
        <span class="eventCard--title__text">{{ `${$t('backend_trans_keys.' + event.transmitter.typeTransKey)} [${event.transmitter.id}]` }}</span>
        <span class="eventCard--title__subText">{{ displayDate(event.createdAt) }}</span>
      </div>

      <div class="eventCard--content text-center">
        <div>{{ this.$t('pages.culture_events.sensor_management-card.removed-sensors') }}: {{ this.removedSensorItems.length  }}</div>
        <div>{{ this.$t('pages.culture_events.sensor_management-card.added-sensors') }}: {{ this.addedSensorItems.length }}</div>
        <button @click="toggleModal" class="btn btn--primary mt-4">{{ $t('words.details') }}</button>
      </div>

    </div>

  </div>
</template>

<script>
import Modal from "../modals/modal";
import {apiPostRequest, displayDateByLocale, getLetterForSensor} from "../../utils";
import {
  API_EVENTS_ACK_SENSOR_MANAGEMENT_SUFFIX,
  API_EVENTS_SHOW,
  EVENT_SENSOR_ITEM_TYPE_ADD,
  EVENT_SENSOR_ITEM_TYPE_REMOVE
} from "../../utils/constants";

export default {
  name: "SensorManagementEvent",
  components: {Modal},
  props: {
    event: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      modalVisible: false,
      userChoice: null,
      addedSensorItems: this.event.eventSensorItems.filter(sensorItem => sensorItem.type === EVENT_SENSOR_ITEM_TYPE_ADD),
      removedSensorItems: this.event.eventSensorItems.filter(sensorItem => sensorItem.type === EVENT_SENSOR_ITEM_TYPE_REMOVE)
    }
  },
  mounted() {
    // Initialisation of all user choice with default values
    this.userChoice = {
      addedSensorItems: this.addedSensorItems.map((sensorItem) => {
        return {
          sensorItemId: sensorItem.id,
          comment: null,
          isReplacement: 0,
          sensorItemReplacementId: this.removedSensorItems.length === 0 ? null : this.removedSensorItems[0].id, // First of the removed item by default
          continueMeasurement: 0
        }
      }),
      removedSensorItems: this.removedSensorItems.map((sensorItem) => {
        return {
          sensorItemId: sensorItem.id,
          comment: null
        }
      })
    }
  },
  methods: {
    toggleModal: function() {
      this.modalVisible = !this.modalVisible
    },
    displayDate: function(d) {
      d = d.slice(0,-1) // Remove last millisecond entry in array to prevent moment.js date error
      return displayDateByLocale(d)
    },
    getLetter: function(sensorId){
      return getLetterForSensor(sensorId)
    },
    acquitEvent: function(){

      // Modify these data to transform all integer bool value to real boolean true false for the backend
      const addedSensorItemsToSend = this.userChoice.addedSensorItems.map((item) => {
          return {
            sensorItemId: item.sensorItemId,
            comment: item.comment,
            replacement: item.isReplacement === 1,
            sensorItemReplacementId: item.sensorItemReplacementId,
            continueMeasurement: item.continueMeasurement === 1
          }
      })

      apiPostRequest(API_EVENTS_SHOW + this.event.id + API_EVENTS_ACK_SENSOR_MANAGEMENT_SUFFIX, {
        "addedSensorItems": addedSensorItemsToSend,
        "removedSensorItems": this.userChoice.removedSensorItems
      })
          .then(() => {
            // Emit to the parent that the event has been acquitted
            this.$emit("acquitted")
          })
          .finally(() => this.toggleModal())
    }
  },
}
</script>

<style scoped>

</style>
