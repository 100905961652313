<template>
  <div>
    <transition name="slide">
      <div v-if="showParameters" class="grid grid-cols-2">
        <Prediction :culture-id="cultureId" :is-history="isHistory"/>
        <FitModel :culture-id="cultureId" :is-history="isHistory"/>
      </div>
    </transition>
    <div class="inline-flex items-center justify-center w-full z-0">
      <hr class="w-64 h-px my-8 bg-black border-0 dark:bg-gray-700 w-full">
      <button @click="onClickShowParameters" class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
        <i :class="classChevronPredictionParameters"></i>
        {{$t('pages.culture_show.prediction.open-parameters')}}
        <i :class="classChevronPredictionParameters"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Prediction from "@/components/model/Prediction.vue";
import FitModel from "@/components/model/FitModel.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "PredictionCurve",
  components: {FitModel, Prediction},
  props:{
    cultureId:{
      type:[Number,String],
      required:true
    },
    isHistory:{
      type:Boolean,
      required:false,
      default: false
    }
  },
  data:()=>{
    return{
      showParameters:false,
    }
  },
  computed:{
    ...mapState(['combined','prediction']),
    ...mapGetters(['minValuePredictionDate','lastGraphDate']),
    classChevronPredictionParameters:function(){
      return `fas fa-chevron-${!this.showParameters ? 'down': 'up'} icon mr-2`
    },
    defaultPredictionDate:function(){
      return this.minValuePredictionDate(this.lastGraphDate)
    },
  },
  methods:{
    onClickShowParameters:function(){
      this.showParameters = !this.showParameters
      this.prediction.selectedPredictionDateUntil = this.defaultPredictionDate
    },

  }
}
</script>

<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>