<template>
  <div class="flex flex-wrap justify-center">
    <div class="text-center font-bold w-full text-red-600">{{$t('pages.first_connection.info')}}</div>
    <div class="w-1/4">
      <div class="m-5 shadow-md rounded-xl bg-white">
        <div class="bg-green-500 rounded-tl-xl rounded-tr-xl h-2"></div>
        <ChangePassword redirect-name="inventory" :user-id="this.$store.state.user.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChangePassword from "@/components/forms/ChangePassword.vue";

export default {
  name: "FirstConnection",
  components: {ChangePassword},

}
</script>

<style scoped>

</style>